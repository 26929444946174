import axios from 'axios';
import { constant } from '@app/utils/constant';
import Cookies from 'js-cookie';
import { GetAppSid } from './appsid';
export const getAccountDetails = async (values: any) => {
  try {
    let AppSid = Cookies.get('AppSid');
    if (!AppSid) {
      AppSid = await GetAppSid();
    }
    console.log("AppSid",AppSid);
    const postData = {
      "AppNamespace": "",
      "AppSid": AppSid,
      "AppVersion": "",
      "CheckVersion": "",
      "IpAddress": localStorage.getItem('IPAddress'),
      "Latitude": "",
      "Longitude": "",
      "LedgerNumber": values.LedgerNumber,
      "ZipCode": values.ZipCode
    };
    const response = await axios.post(constant.GetAccountDetails_Api_Url, postData);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getPaymentForm = async (param: any) => {
  try {
    let AppSid = Cookies.get('AppSid');
    if (!AppSid) {
      AppSid = await GetAppSid();
    }
    let postData = {
      "AppNamespace": "",
      "AppSid": AppSid,
      "AppVersion": "",
      "CheckVersion": "",
      "IpAddress": localStorage.getItem('IPAddress'),
      "Latitude": "",
      "Longitude": "",
      "AppSource": "",
      "IsProd": constant.IsProd,
      "InitPayment": "",
      "Amount": param.updateManuallyPrice
    };
    if (param.PaymentMode == 1) { // 1 for Without Saving Card
      postData.InitPayment = true;
    } else {
      postData.InitPayment = false;
    }
    const response = await axios.post(constant.GetPaymentForm_Api_Url, postData);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const makePayment = async (params: any) => {
  try {
    let AppSid = Cookies.get('AppSid');
    if (!AppSid) {
      AppSid = await GetAppSid();
    }
    let postData = {
      "AppNamespace": "",
      "AppSid": AppSid,
      "AppVersion": "",
      "CheckVersion": "",
      "IpAddress": localStorage.getItem('IPAddress'),
      "Latitude": "",
      "Longitude": "",
      "LedgerNumber": params.LedgerNumber,
      "LastFourDigits": params.CardNumber,
      "ExpiryDate": params.ExpiryDate,
      "Amount": "",
      "CardType": params.CardType,
      "AutoPay": params.EnrollAutoPay,
      "SaveMethod": '',
      "CustomerRefNumber": params.CustomerRefNumber,
      "UID": params.uid,
      "isProd": constant.IsProd
    };

    if (params.PaymentMode == 1) { // 1 for Without Saving Card  
      postData.UID = params.uid;
      postData.Amount = '0';
      postData.SaveMethod = 'N';
      if (params.EnrollAutoPay == 'Y') {
        postData.SaveMethod = 'N';
      }
    } else {
      postData.UID = "";
      postData.Amount = params.Amount;
      postData.SaveMethod = 'N';
    }
    const response = await axios.post(constant.MakePayment_Api_Url, postData);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const addPaymentMethod = async (params: any) => {
  try {
    let AppSid = Cookies.get('AppSid');
    if (!AppSid) {
      AppSid = await GetAppSid();
    }
    const postData = {
      "AppNamespace": "",
      "AppSid": AppSid,
      "AppVersion": "",
      "CheckVersion": "",
      "IpAddress": localStorage.getItem('IPAddress'),
      "Latitude": "",
      "Longitude": "",
      "LedgerNumber": params.LedgerNumber,
      "UID": params.paymentUid,
      "IsProd": constant.IsProd
    };
    const response = await axios.post(constant.AddPayment_Method_Api_Url, postData);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const sendEmailSms = async (params: any) => {
  try {
    let AppSid = Cookies.get('AppSid');
    if (!AppSid) {
      AppSid = await GetAppSid();
    }
    const postData = {
      "AppNamespace": "",
      "AppSid": AppSid,
      "AppVersion": "",
      "CheckVersion": "",
      "IpAddress": localStorage.getItem('IPAddress'),
      "Latitude": "",
      "Longitude": "",
      "PhoneNumber": params.Phone,
      "Email": params.Email
    };

    const response = await axios.post(constant.SendEmailSMS_Api_Url, postData);
    return response;
  } catch (err) {
    console.log(err);
  }
};


